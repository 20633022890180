import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react"; // Auth0 hook
import { Container,  Row, Col, Spinner } from "reactstrap";
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import AuthFooter from "components/Footers/AuthFooter.js";
import config from '../config';

const Privacy = () => {
  const { user, getAccessTokenSilently, getAccessTokenWithPopup, isAuthenticated, loginWithRedirect, isLoading } = useAuth0(); // Destructure Auth0 hooks
  const [policyText, setPolicyText] = useState(""); // State to hold the privacy policy text
  const [userMetadata, setUserMetadata] = useState(null); // State to hold the user metadata
  const [isFetchingToken, setIsFetchingToken] = useState(false); // Flag to prevent multiple requests
  const [isSubmitting, setIsSubmitting] = useState(false); // State for button loading

  // Fetch the privacy policy text when the component mounts
  useEffect(() => {
    fetch("/privacyPolicy.txt")
      .then((response) => response.text())
      .then((text) => setPolicyText(text));
  }, []);

  // Handle the action when user accepts the privacy policy
  const handleAcceptPolicy = async () => {
    setIsSubmitting(true); // Set loading to true
    try {
      const token = await getAccessTokenSilently();
      const email = user.email;
      const apiUrl = `${config.apiUrl}/${config.apiStage}/privacy`; // Updated API route
    
      const response = await fetch(apiUrl, {
        method: 'PUT', // Assuming the API uses POST; change to GET if needed
        headers: {
          Authorization: `Bearer ${token}`,
        },
   
      });

      if (!response.ok) {
        throw new Error("Error updating privacy policy acceptance");
      }

      loginWithRedirect();
    } catch (error) {
      console.error("Error updating user metadata:", error);
    } finally {
    setIsSubmitting(false); // Set loading back to false
    }
  };

  // Check the claim directly from the user object
  const privacyPolicyAccepted = user?.privacypolicystatus || false;

  if (isLoading) {
    return (
      <>
        <div className="main-content">  
        <AuthNavbar />
          <div className="header bg-gradient-info pb-8 pt-7 pt-md-8">
            <Container className="d-flex align-items-center justify-content-center" style={{ height: '50vh' }}>
              <Row>
              <Col className="text-center">
                <Spinner color="info" />  {/* 'info' is lighter than 'primary' */}
                <div className="text-muted">Loading...</div>
              </Col>
              </Row>
            </Container>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div>
        <div className="main-content">
          <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
            <AuthNavbar />
            <Container style={{ marginTop: "70px" }}>
              {/* Display the privacy policy */}
              <pre
                style={{ whiteSpace: "pre-wrap", wordWrap: "break-word", color: "white" }}
              >
                {policyText}
              </pre>

              {/* Show the "Accept Privacy Policy" button if the user is authenticated */}
              {isAuthenticated && !privacyPolicyAccepted && (
                <button
                  onClick={handleAcceptPolicy}
                  className="btn btn-primary"
                  disabled={isSubmitting} // Disable button when submitting
                >
                  {isSubmitting ? (
                    <>
                      <Spinner size="sm" color="light" className="me-2" />
                      Accepting...
                    </>
                  ) : (
                    "Accept Privacy Policy"
                  )}
                </button>
              )}
            </Container>
            <AuthFooter />
          </div>
        </div>
      </div>
    </>
  );
};

export default Privacy;
