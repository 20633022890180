import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';

import UserHome from 'layouts/UserHome';
import UserProfile from 'layouts/UserProfile';
import AuthCallback from 'layouts/AuthCallback';
import QuillLayout from 'layouts/Quill';
import HomeLayout from 'layouts/Home';
import UserEditQuill from 'layouts/UserEditQuill';
import UserPrintQuill from 'layouts/UserPrintQuill';
import AdminHome from 'layouts/AdminHome';
import ProtectedRoute from './protectedroute';
import config from './config';
import Privacy from 'layouts/Privacy';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import Contact from 'layouts/Contact';
import Register from 'layouts/Register';
import Login from 'Login';
import Store from 'layouts/Store'; 
import MaintenanceBanner from 'views/MaintenanceBanner'; // Import the banner component



const onRedirectCallback = (appState) => {
  window.history.replaceState({}, document.title, appState?.returnTo || window.location.pathname);
};

const App = () => {
  return (
    <>
    <MaintenanceBanner />
    <Routes>
      <Route path="/" element={<HomeLayout />} />
      <Route path="/index.html" element={<Navigate to="/" />} />
      <Route path="/callback" element={<AuthCallback />} />
      <Route path="/q/:param?" element={<QuillLayout />} />
      <Route path="/quill/:param?" element={<QuillLayout />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/register" element={<Register />} />
      <Route path="/login" element={<Login />} />
      <Route path="/store" element={<Store />} />
      <Route path="/User/Home" element={<ProtectedRoute component={UserHome} />} />
      <Route path="/Admin" element={<ProtectedRoute component={AdminHome} requireUsername={true} />} />
      <Route path="/User/editquill/:param" element={<ProtectedRoute component={UserEditQuill} />} />
      <Route path="/User/printquill/:param" element={<ProtectedRoute component={UserPrintQuill} />} />
      <Route path="/User/Profile" element={<ProtectedRoute component={UserProfile} />} />
      
      {/* Catch-all route to handle undefined paths */}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
    </>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Auth0Provider
    domain={config.auth0.domain}
    clientId={config.auth0.clientId}
    authorizationParams={{
      redirect_uri: window.location.origin + '/callback', // Updated key
      audience: config.auth0.audiance,
      responseType: 'token id_token',
      scope: `${config.auth0.oidcscopes}`,
    }}
    onRedirectCallback={onRedirectCallback}
    useRefreshTokens={true}
    cacheLocation="localstorage"
  >
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Auth0Provider>
);

// Register the service worker
serviceWorkerRegistration.register();