import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useParams } from "react-router-dom";
// reactstrap components
import { Container, Row, Col, Button } from "reactstrap";
import { useAuth0 } from '@auth0/auth0-react';

// core components
import AuthFooter from "components/Footers/AuthFooter.js";
import BasicNote from "components/Notes/BasicNote";
import config from '../config.js';
import QuillNavBar from "components/Navbars/QuillNavBar.js";
import Likes from 'components/Notes/Likes.js';
import "./Quill.css"; // Import the custom CSS file


const Quill = (props) => {
  const mainContent = useRef(null);
  const location = useLocation();
  const { param } = useParams();
  const [message, setMessage] = useState('');
  const [error, setError] = useState(null);
  const [quillLoading, setQuillLoading] = useState(true);
  const [owner, setOwner] = useState(false); // New state to track owner status
  const [guuid, setGuuid] = useState(false); 
  const [imageUrl, setImageUrl] = useState(null); // State for image URL

  // Auth0 methods
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    document.body.classList.add("bg-default");
    return () => {
      document.body.classList.remove("bg-default");
    };
  }, []);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    if (mainContent.current) {
      mainContent.current.scrollTop = 0;
    }
  }, [location]);

  useEffect(() => {
    if (param) {
      // Define the API Gateway endpoint
      const apiUrl = `${config.apiUrl}/${config.apiStage}/q/${param}`;
  
      // Fetch data from API Gateway
      const fetchQuillData = async () => {
        try {
          const headers = { }; // Base headers
  
          try {
            // Attempt to retrieve the access token
            const token = await getAccessTokenSilently();
            headers['Authorization'] = `Bearer ${token}`; // Add token if available
          } catch (error) {
            console.warn("Access token not available. Proceeding without it.");
            console.error(error)
          }
  
          const response = await fetch(apiUrl, { headers });
          const responseData = await response.json();
  
          if (!response.ok) {
            console.error("Error response data:", responseData.error);
  
            if (responseData.redirectTo) {
              // eslint-disable-next-line no-alert
              const userConfirmation = window.confirm("This Quill has no current owner. Would you like to take ownership?");
            
              if (userConfirmation) {
                          // Check if there is a stored redirect URL
                const redirectUrl = localStorage.getItem('redirectUrl');
                console.log(redirectUrl)

                // Redirect to the specified URL
                window.location.href = responseData.redirectTo;
              } else {
                // Redirect to the home page
                window.location.href = "/";
              }
              return;
            }
  
            if (response.status === 404 && responseData.error === "Invalid or missing GUUUID") {
              setMessage("This quill doesn't exist... \n\nJoin today to create one of your own!");
            } else {
              throw new Error(responseData.error || `HTTP error! status: ${response.status}`);
            }
            return; // Stop further processing
          }
  
          // If response is OK, process the data
          if (responseData.message) {
            setMessage(responseData.message);
            setGuuid(responseData.guuid);
          } else {
            setMessage("No message available.");
          }
          // Check if owner is true in the response and set the owner state
          if (responseData.owner === true) {
            setOwner(true);

          }

          // Check if signedimage exists and set imageUrl state

          if (responseData.signedImage) {
            console.log("Signed image URL:", responseData.signedimage);
            setImageUrl(responseData.signedImage); // Set image URL if it exists
          }

        } catch (error) {
          console.error("Fetch error:", error);
          setMessage(error.message || 'An unexpected error occurred');
          setError(error.message);
        } finally {
          setQuillLoading(false); // Ensure loading state is updated
        }
      };
  
      fetchQuillData(); // Call the fetch function
    } else {
      setMessage('This is an example static Note \n\n -Grant');
      setQuillLoading(false);
    }
  }, [param, getAccessTokenSilently]);

  return (
    <>
      <div className="d-flex flex-column min-vh-100 bg-gradient-info" ref={mainContent}>
        <QuillNavBar />
        
        {/* Main Content Wrapper */}
        <Container className="flex-grow-1 d-flex flex-column">
          <Row className="justify-content-center flex-grow-1">
            <Col lg="12" md="3" sm="1" className="d-flex flex-column flex-grow-1">
              
              {/* Centered Edit Button */}
              {owner && (
                <div className="d-flex justify-content-center mt-1">
                  <Button
                    color="secondary"
                    onClick={() => window.location.href = `/User/editquill/${guuid}`}
                  >
                    Edit your Quill
                  </Button>
                </div>
              )}
  
              {/* Image Display */}
              {imageUrl && (
                <div className="text-center mb-3 mt-5">
                  <img src={imageUrl} alt="Quill image" style={{ minWidth: '350px', maxWidth: '100%', maxHeight: '400px' }} />
                </div>
              )}
  
              {/* Message Content */}
              <div className="flex-grow-1">
                <BasicNote data={message} />
              </div>
  
            </Col>
          </Row>
        </Container>
  
        <AuthFooter />
      </div>
  
      {/* Likes Button Fixed to Bottom-Left */}
      <div className="fixed-bottom-left p-3">
        <Likes quillId={guuid} />
      </div>
    </>
  );
};

export default Quill;
