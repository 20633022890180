import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Spinner } from "reactstrap";
import config from "../config";
import "./Modal.css"; // Import the custom CSS file

const UsernameModal = ({ onSuccess }) => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [username, setUsername] = useState("");
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchUsername = async () => {
      try {
        const token = await getAccessTokenSilently();
        const apiUrl = `${config.apiUrl}/${config.apiStage}/user`;
        const response = await fetch(apiUrl, {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (response.ok) {
          const data = await response.json();
          if (data.username) {
            setUsername(data.username);
          }
        }
      } catch (error) {
        console.error("Error fetching username:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUsername();
  }, [getAccessTokenSilently]);

  const handleSaveUsername = async () => {
    if (!username.trim()) {
      setError("Username cannot be empty.");
      return;
    }

    setIsSubmitting(true);
    setError(null);

    try {
      const token = await getAccessTokenSilently();
      const apiUrl = `${config.apiUrl}/${config.apiStage}/user`;
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ username }),
      });

      if (response.ok) {
        onSuccess();
      } else {
        const data = await response.json();
        setError(data.message || "Username already exists. Try another.");
      }
    } catch (error) {
      console.error("Error setting username:", error);
      setError("Failed to set username. Try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-card">
        <h2 className="modal-title">{username ? "Update Your Username" : "Set Your Username"}</h2>

        {isLoading ? (
          <div className="modal-loading">
            <Spinner color="info" />
            <p>Checking username...</p>
          </div>
        ) : (
          <div className="modal-body">
            <input
              type="text"
              placeholder="Enter a username"
              className="modal-input"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            {error && <p className="modal-error">{error}</p>}
          </div>
        )}

        {isAuthenticated && (
          <div className="modal-footer">
            <button
              onClick={handleSaveUsername}
              className="btn btn-primary"
              disabled={isSubmitting || isLoading}
            >
              {isSubmitting ? (
                <>
                  <Spinner size="sm" color="light" className="me-2" />
                  Saving...
                </>
              ) : (
                "Save"
              )}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default UsernameModal;
