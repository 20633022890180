import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Row, Col, Spinner } from "reactstrap";

const AuthCallback = () => {
  const { handleRedirectCallback, isAuthenticated } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleAuthCallback = async () => {
      const params = new URLSearchParams(location.search);
      const error = params.get('error');
      const errorDescription = params.get('error_description');

      if (error) {
        console.error('Error handling authentication callback:', error, errorDescription);
        if (error === 'access_denied' && errorDescription === 'email_verification_required') {
          navigate('/register'); // Redirect to a custom email verification page
        } else {
          navigate('/error'); // Redirect to a generic error page
        }
        return;
      }

      try {
        await handleRedirectCallback();
      } catch (err) {
        console.error('Error handling authentication callback:', err);
        navigate('/error'); // Navigate to an error page if needed
      }
    };

    handleAuthCallback();
  }, [handleRedirectCallback, navigate, location.search]);

  useEffect(() => {
    if (isAuthenticated) {
      // Check if the 'redirectTo' query parameter exists
      const params = new URLSearchParams(location.search);
      const redirectTo = params.get('redirectTo');
      
      if (redirectTo) {
        // Redirect user to the original requested page (use `navigate` to go back)
        navigate(decodeURIComponent(redirectTo));
      } else {
        // Default redirect after login (e.g., home page or user dashboard)
        navigate('/User/Home');
      }
    }
  }, [isAuthenticated, location.search, navigate]);

  return (
    <div className="header bg-gradient-info pb-8 pt-7 pt-md-8">
            <Container className="d-flex align-items-center justify-content-center" style={{ height: '50vh' }}>
              <Row>
              <Col className="text-center">
                <Spinner color="info" />  {/* 'info' is lighter than 'primary' */}
                <div className="text-muted">Loading...</div>
              </Col>
              </Row>
            </Container>
          </div>
  );
};

export default AuthCallback;
