import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Container, Row, Col, Spinner } from "reactstrap";
import PrivacyModal from "./layouts/PrivacyModal";
import UsernameModal from "./layouts/UsernameModal";

const ProtectedRoute = ({ component: Component, requireUsername, ...rest }) => {
  const {
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    getAccessTokenSilently,
    getIdTokenClaims,
  } = useAuth0();

  const navigate = useNavigate();
  const location = useLocation();

  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  const [missingUsername, setMissingUsername] = useState(false);

  useEffect(() => {
    const checkAuthenticationAndPrivacy = async () => {
      if (isLoading) return;

      if (!isAuthenticated) {
        // Store redirect URL **before login**, only if not set
        if (!localStorage.getItem("redirectUrl")) {
          const returnTo = location.pathname + location.search;
          console.log("Storing redirect URL before login:", returnTo);
          localStorage.setItem("redirectUrl", returnTo);
        }

        loginWithRedirect();
        return;
      }

      if (isAuthenticated) {
        try {
          await getAccessTokenSilently();
          const claims = await getIdTokenClaims();

          const privacyPolicyStatus = claims?.["privacypolicystatus"];
          const username = claims?.["username"];

          // If the user hasn't accepted the privacy policy, store redirect and show modal
          if (privacyPolicyStatus === false) {
            console.log("Privacy policy not accepted, storing redirect and showing modal");

            if (!localStorage.getItem("redirectUrl")) {
              const returnTo = location.pathname + location.search;
              localStorage.setItem("redirectUrl", returnTo);
            }

            setShowPrivacyModal(true);
            return;
          }

          // If username is required but missing, store redirect **before showing modal**
          if (requireUsername && !username) {
            console.log("Missing username, storing redirect & showing username modal");

            if (!localStorage.getItem("redirectUrl")) {
              const returnTo = location.pathname + location.search;
              localStorage.setItem("redirectUrl", returnTo);
            }

            setMissingUsername(true);
            return;
          }

          // Redirect to stored URL if everything is fine
          const redirectUrl = localStorage.getItem("redirectUrl");
          if (redirectUrl) {
            console.log("Redirecting to:", redirectUrl);
            localStorage.removeItem("redirectUrl");
            navigate(redirectUrl);
          }
        } catch (error) {
          console.error("Error in authentication checks:", error);
          loginWithRedirect();
        }
      }
    };

    checkAuthenticationAndPrivacy();
  }, [
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
    getIdTokenClaims,
    loginWithRedirect,
    navigate,
    location.pathname,
    location.search,
    requireUsername,
  ]);

  const handlePrivacyAccept = async () => {
    console.log("Privacy accepted, refreshing token...");
    await loginWithRedirect(); // Refresh ID token after accepting privacy
  };

  const handleUsernameUpdate = async () => {
    console.log("Username updated, refreshing token...");
    await loginWithRedirect(); // Refresh ID token after updating username
  };

  if (isLoading) {
    return (
      <div className="main-content">
        <div className="header bg-gradient-info pb-8 pt-7 pt-md-8">
          <Container className="d-flex align-items-center justify-content-center" style={{ height: "50vh" }}>
            <Row>
              <Col className="text-center">
                <Spinner color="info" />
                <div className="text-muted">Loading...</div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }

  return (
    <>
      {isAuthenticated && showPrivacyModal && (
        <div className="header bg-gradient-info pb-8 pt-7 pt-md-8">
          <PrivacyModal
            onAccept={handlePrivacyAccept}
            onCancel={() => {
              navigate('/');
              console.warn("Privacy policy must be accepted to proceed.");
            }}
          />
        </div>
      )}
      {isAuthenticated && missingUsername && <UsernameModal onSuccess={handleUsernameUpdate} />}

      {isAuthenticated && !showPrivacyModal && !missingUsername && <Component {...rest} />}
    </>
  );
};

export default ProtectedRoute;
