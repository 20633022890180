import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Spinner } from "reactstrap";
import config from "../config";
import './Modal.css'; // Import the custom CSS file

const PrivacyModal = ({ onAccept, onCancel }) => {
  const { user, getAccessTokenSilently, isAuthenticated, loginWithRedirect } = useAuth0();
  const [policyText, setPolicyText] = useState(""); // State for the privacy policy text
  const [isSubmitting, setIsSubmitting] = useState(false); // State for button loading
  const [isLoading, setIsLoading] = useState(true); // State for loading the policy text

  useEffect(() => {
    // Fetch the privacy policy text when the modal opens
    fetch("/privacyPolicy.txt")
      .then((response) => response.text())
      .then((text) => {
        setPolicyText(text);
        setIsLoading(false); // Stop loading
      })
      .catch((error) => {
        console.error("Error fetching privacy policy text:", error);
        setIsLoading(false);
      });
  }, []);

  // Handle privacy policy acceptance
  const handleAcceptPolicy = async () => {
    setIsSubmitting(true);
    try {
      const token = await getAccessTokenSilently();
      const apiUrl = `${config.apiUrl}/${config.apiStage}/privacy`;
  
      const response = await fetch(apiUrl, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (!response.ok) {
        throw new Error(`Error updating privacy policy acceptance: ${response.statusText}`);
      }
  
      // Once the privacy policy is successfully accepted, call the onAccept callback
      onAccept(); // Notify the parent that the policy was accepted
      
    } catch (error) {
      console.error("Error accepting privacy policy:", error);
    } finally {
      setIsSubmitting(false);
    }
  };
  

  return (
    <div className="modal-overlay">
      <div className="modal-card">
        <h2 className="modal-title">Privacy Policy</h2>

        {isLoading ? (
          <div className="modal-loading">
            <Spinner color="info" />
            <p>Loading privacy policy...</p>
          </div>
        ) : (
          <div className="modal-body">
            <pre className="policy-text">
              {policyText}
            </pre>
          </div>
        )}

        {isAuthenticated && (
          <div className="modal-footer">
            <button
              onClick={handleAcceptPolicy}
              className="btn btn-primary"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <>
                  <Spinner size="sm" color="light" className="me-2" />
                  Accepting...
                </>
              ) : (
                "Accept"
              )}
            </button>
            <button onClick={onCancel} className="btn btn-secondary">
              Cancel
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default PrivacyModal;
